<template>
  <b-container fluid>
    <b-alert v-if="error" show variant="danger">{{
      error.data.errors[Object.keys(error.data.errors)[0]][0]
    }}</b-alert>
    <b-alert v-if="valiationErrorMessage" show variant="danger">{{
      valiationErrorMessage
    }}</b-alert>
    <div v-if="isLoading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section v-else>
      <Step-Wizard :step="2"></Step-Wizard>
      <form @submit="addRow">
        <b-button type="submit" hidden></b-button>
        <b-row>
          <b-col>
            <!-- Order lines card START -->
            <section id="orderLines">
              <b-card>
                <h4>Order lines</h4>
                <hr />
                <b-form-group>
                  <div class="">
                    <p>Use this step to add items to the order.</p>
                    <b-button
                      class=""
                      variant="primary"
                      @click="showAddItemModal"
                      >Add items</b-button
                    >
                    <b-button
                      class="ml-2"
                      variant="outline-secondary"
                      @click="toggleTable"
                      >Toggle list</b-button
                    >
                  </div>
                </b-form-group>
              </b-card>

              <div>
                <Order-Lines-Card
                  :order="getCurrentOrder"
                  v-if="!showTable"
                ></Order-Lines-Card>
                <Order-Lines-Table
                  :order="getCurrentOrder"
                  :isAdmin="true"
                  v-else
                ></Order-Lines-Table>
              </div>
            </section>
            <!-- Order lines card END -->
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="danger" @click="previous"> Previous</b-button>
            <b-button variant="primary" @click="next" class="mt-2 pull-right"
              >Next</b-button
            >
          </b-col>
        </b-row>
      </form>
    </section>

    <Add-Item-Modal></Add-Item-Modal>
    <Edit-Item-Modal></Edit-Item-Modal>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StepWizard from "@/components/StepWizard";
import OrderLinesTable from "@/components/OrderLinesTable";
import OrderLinesCard from "@/components/OrderLinesCard";
import AddItemModal from "@/components/AddItemModal";
import EditItemModal from "@/components/EditItemModal";

export default {
  name: "StepTwo",
  components: {
    StepWizard,
    OrderLinesTable,
    OrderLinesCard,
    AddItemModal,
    EditItemModal
  },
  data() {
    return {
      isLoading: true,
      error: null,
      valiationErrorMessage: null,
      orderId: null,
      orderNo: null,
      showTable: true
    };
  },
  created() {
    this.orderNo = this.$route.query.orderno || null;
    this.orderId = this.$route.query.id || null;

    if (this.orderId) {
      const self = this;
      this.loadOrder(this.orderId)
        .then(r => {
          if (r.data.statusNo !== "1") {
            //if status any other than 10 it's sendt and the admin must handle it form EDIT form.
            self.$router.push(`/order/view/${this.orderId}`);
          }
          if (this.getCurrentOrder.orderLines.length === 0) {
            this.addNewRow();
          }
          this.isLoading = false;
        })
        .catch(e => {
          self.error = "Failed when loading order: " + e;
        });
    } else {
      this.$router.push("/order/new/1");
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions([
      "setCurrentOrderLine",
      "setCurrentOrderLineId",
      "loadOrder",
      "updateCreateCurrentOrder",
      "addNewRow"
    ]),
    showAddItemModal() {
      this.$bvModal.show("addItemModal");
    },
    toggleTable() {
      this.showTable = !this.showTable;
    },
    next() {
      var self = this;
      window.scrollTo(0, 0);
      var formOk = true;
      //evt.preventDefault();
      // checking orderlines for active items
      this.getCurrentOrder.orderLines.forEach(item => {
        if (!item.name) {
          formOk = false;
        }
      });
      this.isLoading = true;
      if (formOk) {
        this.updateCreateCurrentOrder(this.getCurrentOrder)
          .then(() => {
            self.$router.push({
              path: "/order/new/3",
              query: {
                id: self.orderId
              }
            });
          })
          .catch(e => {
            self.isLoading = false;
            self.error = e;
          });
      } else {
        this.valiationErrorMessage = "Missing item";
        this.isLoading = false;
      }
    },
    previous() {
      this.$router.push({
        path: "/order/new/1",
        query: {
          id: this.orderId
        }
      });
    },
    addRow(evt) {
      evt.preventDefault();
      this.addNewRow();
    }
  },
  computed: {
    ...mapGetters(["getCurrentOrder"])
  },
  watch: {
    orderLines() {}
  }
};
</script>

<style></style>
